import type {inferReactQueryProcedureOptions} from '@trpc/react-query';
import {createTRPCReact} from '@trpc/react-query';
import type {TRPCClientErrorLike} from '@trpc/client';
import {createTRPCProxyClient, httpBatchLink} from '@trpc/client';
import {TRPC_ERROR_CODES_BY_KEY} from '@trpc/server/rpc';
import superjson from 'superjson';
import type {inferRouterInputs, inferRouterOutputs} from '@trpc/server';
import {HttpStatusCode} from '@local/backend/@types/updated-api-types/errors/HttpStatusCode';
import type {AppRouter} from '@local/backend';
import {startSpan, captureException, addBreadcrumb} from '@sentry/react';
import {BACKEND_CONFIG} from '../../config/backend';

export type TrpcError = TRPCClientErrorLike<AppRouter>;
export type TrpcReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type TrpcRouterInputs = inferRouterInputs<AppRouter>;
export type TrpcRouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${BACKEND_CONFIG.URL}/trpc`,
      fetch(url, options) {
        return startSpan(
          {
            name: url instanceof URL ? url.href : url.toString(),
            op: 'http.client',
          },
          async span => {
            addBreadcrumb({
              level: 'info',
              message: `making request to ${url}`,
              data: options,
            });

            const res = await fetch(url, {
              ...options,
              credentials: 'include',
            });

            span?.setAttribute('http.status_code', res?.status);

            if (!res.ok) {
              captureException(
                new Error(
                  `HTTP Request Failed: ${res.status} ${res.statusText}`
                )
              );
            }

            return res;
          }
        );
      },
    }),
  ],
  transformer: superjson,
});

// user
export const useGetUser = trpc.user.get.useQuery;

// env
export const useEnvironmentSession = trpc.session.getEnvironment.useQuery;

// merchants
export const useGetTeamMember = trpc.merchants.getTeamMemberProfile.useQuery;
export const useMerchantPermissions = trpc.merchants.getPermissions.useQuery;
export const useMerchant = trpc.merchants.get.useQuery;
export const useMerchantTeamMembers = trpc.merchants.getTeamMembers.useQuery;
export const useMerchantTeamInvites = trpc.merchants.getTeamInvites.useQuery;
export const useMerchantTeamMemberProfile =
  trpc.merchants.getTeamMemberProfile.useQuery;
export const useMerchantPublic = trpc.merchants.getPublicProfile.useQuery;
export const useMerchantAccounts = trpc.merchants.list.useQuery;
export const useUpdateMerchantAccount = trpc.merchants.update.useMutation;
export const useDeleteMerchantMutation = trpc.merchants.delete.useMutation;

// merchant teams
export const useMerchantTeamInviteMutation = trpc.merchants.invite.useMutation;
export const useMerchantJoinTeamMutation = trpc.merchants.join.useMutation;
export const useMerchantRemoveTeamMemberMutation =
  trpc.merchants.remove.useMutation;
export const useMerchantLeaveTeamMutation = trpc.merchants.leave.useMutation;
export const useMerchantUpdateRoleMutation =
  trpc.merchants.updateRole.useMutation;
export const useMerchantCancelTeamInviteMutation =
  trpc.merchants.cancelInvite.useMutation;

// webhooks
export const useListWebhookEndpoints = trpc.webhooks.list.useQuery;
export const useGetWebhookEndpoint = trpc.webhooks.get.useQuery;
export const useUpdateWebhookEndpoint = trpc.webhooks.update.useMutation;
export const useFindWebhookDeliveryAttempts =
  trpc.webhooks.findWebhookDeliveryAttempts.useQuery;
export const useFindInfiniteWebhookDeliveryAttempts =
  trpc.webhooks.findWebhookDeliveryAttempts.useInfiniteQuery;
export const useFindInfiniteWebhookDeliveryAttemptsForEvents =
  trpc.webhooks.findWebhookDeliveryAttemptsForEvent.useInfiniteQuery;

// events
export const useGetEvent = trpc.events.get.useQuery;
export const useListEvents = trpc.events.list.useQuery;

// products
export const useListProducts = trpc.products.list.useQuery;
export const useListInfiniteProducts = trpc.products.list.useInfiniteQuery;

// payments
export const usePayment = trpc.payments.get.useQuery;
export const useListPayment = trpc.payments.list.useQuery;
export const useListInfinitePayments = trpc.payments.list.useInfiniteQuery;
export const useGetBatchPayments = trpc.payments.getBatch.useQuery;
export const useCancelPayment = trpc.payments.cancel.useMutation;
export const useRefundPayment = trpc.payments.refund.useMutation;
export const useCapturePayment = trpc.payments.capture.useMutation;

// group payments
export const useGroupPayment = trpc.groupPayments.get.useQuery;
export const useCreateGroupPayment = trpc.groupPayments.create.useMutation;
export const useUpdateGroupPayment = trpc.groupPayments.update.useMutation;
export const useCaptureGroupPayment = trpc.groupPayments.capture.useMutation;
export const useCancelGroupPayment = trpc.groupPayments.cancel.useMutation;
export const useJoinGroupPayment = trpc.groupPayments.join.useMutation;
export const useInviteToGroupPayment = trpc.groupPayments.invite.useMutation;
export const useRefundGroupPayment = trpc.groupPayments.refund.useMutation;
export const useKickFromGroupPayment = trpc.groupPayments.kick.useMutation;
export const useRemoveCustomerFromGroupPayment =
  trpc.groupPayments.remove.useMutation;
export const useLeaveGroupPayment = trpc.groupPayments.leave.useMutation;
export const useGroupPaymentCustomerShares =
  trpc.groupPayments.getMemberShares.useQuery;
export const useListInfiniteGroupPayments =
  trpc.groupPayments.list.useInfiniteQuery;

// multi-card payments
export const useMultiCardPaymentInfiniteList =
  trpc.multiCardPayments.list.useInfiniteQuery;
export const useMultiCard = trpc.multiCardPayments.get.useQuery;
export const useCreateMultiCard = trpc.multiCardPayments.create.useMutation;
export const useUpdateMultiCard = trpc.multiCardPayments.update.useMutation;
export const useCancelMultiCard = trpc.multiCardPayments.cancel.useMutation;
export const useRefundMultiCard = trpc.multiCardPayments.refund.useMutation;
export const useCaptureMultiCard = trpc.multiCardPayments.capture.useMutation;

// checkouts
export const useCheckout = trpc.checkouts.get.useQuery;
export const useListCheckouts = trpc.checkouts.list.useQuery;
export const useListInfiniteCheckouts = trpc.checkouts.list.useInfiniteQuery;
export const useCreateCheckout = trpc.checkouts.create.useMutation;
export const useUpdateCheckout = trpc.checkouts.update.useMutation;

// customers
export const useCustomer = trpc.customers.get.useQuery;
export const useListCustomers = trpc.customers.list.useQuery;
export const useListInfiniteCustomers = trpc.customers.list.useInfiniteQuery;
export const useGetBatchCustomers = trpc.customers.getBatch.useQuery;
export const useUpdateCustomer = trpc.customers.update.useMutation;
export const useCreateCustomer = trpc.customers.create.useMutation;

// items
export const useCreateItem = trpc.items.create.useMutation;
export const useUpdateItem = trpc.items.update.useMutation;
export const useGetItem = trpc.items.get.useQuery;
export const useGetBatchItems = trpc.items.getBatch.useQuery;

// emails
export const useSendEmail = trpc.emails.sendEmail.useMutation;

// sms
export const useSendSms = trpc.sms.sendSms.useMutation;

// connections
export const useListGatewayConnections = trpc.connections.list.useQuery;
export const useCreatePaymentGatewayConnections =
  trpc.connections.createConnection.useMutation;
export const useGetPaymentGatewayRedirect =
  trpc.connections.getRedirectUrl.useMutation;
export const useArchiveGatewayConnection =
  trpc.connections.archiveConnection.useMutation;
export const useRestoreGatewayConnection =
  trpc.connections.restoreConnection.useMutation;
export const useDisableGatewayConnection =
  trpc.connections.disableConnection.useMutation;
export const useUpdatePaymentGatewayConnection =
  trpc.connections.updateConnection.useMutation;

// refunds
export const useRefund = trpc.refunds.get.useQuery;
export const useListRefunds = trpc.refunds.list.useQuery;

// billing
export const useListPaymentMethods = trpc.billing.getPaymentMethods.useQuery;
export const useRemovePaymentMethodMutation =
  trpc.billing.removePaymentMethod.useMutation;

// shuttle
export const useCreateShuttleDeepLink = trpc.shuttle.createDeepLink.useMutation;

// 3rd party connect
export const useReadmeRedirectURL = trpc.readme.getRedirectUrl.useQuery;

export function isUnauthorizedError(err: TrpcError): boolean {
  return (
    err?.shape?.code === TRPC_ERROR_CODES_BY_KEY.UNAUTHORIZED ||
    err?.data?.axiosError?.response?.status === HttpStatusCode.UNAUTHORIZED
  );
}

export function isForbiddenError(err: TrpcError): boolean {
  return (
    err?.shape?.code === TRPC_ERROR_CODES_BY_KEY.FORBIDDEN ||
    err?.data?.axiosError?.response?.status === HttpStatusCode.FORBIDDEN
  );
}
